<template>
  <div class="ml-5 mr-5">
    <div class="text-right">
      <loading
        :active.sync="isLoading"
        :can-cancel="false"
        loader="dots"
        color="#007bff"
        :height="45"
        :width="75"
        :is-full-page="fullPage"
      ></loading>
    </div>

<div class="text-left">
        <v-row>
         <v-col cols="3">
      <v-text-field
        v-model="query"
        label="Search By Affinity Name"
        required
        v-on:keyup.enter="searchAffinity"
      ></v-text-field>
          </v-col>
           <v-col cols="2">
            <v-select
              :items="searchCategories"
              :menu-props="{ top: true, offsetY: true }"
              label="Categories"
              v-model="categ"
            ></v-select>
          </v-col>
          <v-col cols="2" class="my-4 px-0">
            <v-btn small color="primary" @click="searchAffinity()">
              Search
        </v-btn>
        <v-btn small  color="default" @click="resetSearch()">
              Reset
        </v-btn>
         </v-col> 
         <v-col cols="3">            
            <v-checkbox
              v-model="show_zero_product_count"
              label="Affinities without products"
              color="primary"
              value="yes"
              hide-details
              @click="searchAffinity()"
            ></v-checkbox>
       </v-col>
      <v-col cols="2" class="my-4 text-right">
            <v-btn small class="mx-4" color="primary" dark 
            @click.prevent="$router.push('/merge-affinities');"
            v-show="checkIfOperationExistForModule('edit')">
              Merge Affinities
            </v-btn>
          </v-col>
             </v-row>
        <v-row>
          <v-col cols="9"></v-col>
          <v-col cols="3" class="my-4 text-right">
              <template>
        <v-dialog v-model="dialog"  persistent
      transition="dialog-bottom-transition"
      max-width="700">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small class="mx-2" color="primary" dark v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('add')">
              <strong>Add</strong>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">Add Affinity</span>
            </v-card-title>
            <v-form ref="form" @submit="addAffinityData" method="post">
              <v-container class="px-50" fluid>
                <v-text-field
                  v-model="addAffinity.affinityName"
                  label="Affinity Name"
                  required
                  :rules="nameRules"
                ></v-text-field>
                <div style="color: red">
                  {{ message1 }}
                </div>
                <v-select
                  v-model="addAffinity.category"
                  :items="categories"
                  :menu-props="{ maxHeight: '400' }"
                  label="Select Categories"
                  multiple
                  @change="findSubCateg(addAffinity.category)"
                ></v-select>

                <div v-if="subcategories !== null && addAffinity.category">
                  <v-select
                    :items="subcategories"
                    label="Sub-categories"
                    multiple
                    v-model="addAffinity.subCategory"
                  ></v-select>
                </div>
              </v-container>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" type="submit" @click="validate">
                  Submit
                </v-btn>
                <v-btn class="close_button m-2" text @click="reset"> Close </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-dialog>
      </template>

      <v-dialog max-width="600" v-model="dialog2">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small color="error" v-bind="attrs" v-on="on" v-show="checkIfOperationExistForModule('delete')"><strong>Delete</strong></v-btn>
        </template>
        <div v-if="deleteItems.length > 0">
          <template>
            <v-card>
              <v-card-text>
                <div class="text-h3 pa-12">
                  Are you sure you want to delete all the selected Record(s)?
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-form ref="form" method="post">
                  <v-btn
                    text
                    class="confirm_button mx-2"
                    v-on:click="dialog2 = false"
                    @click="deleteAffinity"
                    >Yes</v-btn
                  >
                </v-form>
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
        <div v-if="deleteItems.length == 0">
          <template>
            <v-card>
              <v-card-text class="center">
                <div class="text-h3 pa-12">
                  Please Select Record(s) to delete
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn class="close_button mx-2" text @click="dialog2 = false">
                  Close
                </v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </div>
      </v-dialog>
         </v-col>
       </v-row>
</div>
        <!-- v-data-table  -->
    <v-row>
      <v-col cols="12">
          <div class="tableWrapper">
            <div id="select-all-checkbox">
              <v-checkbox
                ref="n"
              color="default"
                v-model="allSelected"
                @click="selectAll"
              ></v-checkbox>
            </div>
            <v-data-table
              :headers="headers"
              item-key="product_id"
              :page.sync="page"
              :items="affinity_data"
              :options.sync="options"
              :server-items-length="totalAffinity"
              :loading="isLoading"
              class="elevation-1"
              hide-default-footer
              @page-count="pageCount = $event"
            >
              <template v-slot:item.selectCheckbox="{ item }">
                <v-checkbox
                  ref="n"
                  v-if="item.product_count === 0"
                  v-model="deleteItems"
                  :value="item.affinity_id"
                  :key="item.affinity_id"
                  @click="addToDelete($event, item.affinity_id)"
                ></v-checkbox>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon
                      medium
                      color="grey"
                      v-show="checkIfOperationExistForModule('edit')"
                      @click="addToUpdate($event, item.affinity_id)"
                    >
                      mdi-pencil
                    </v-icon>
              </template>
            </v-data-table>
            <div class="text-center pt-4 pb-4">
              <Pagination
            :isloading="isLoading"
            :startRecord="startRecord"
            :currentPage="currentPage"
            :lastPage="lastPage"
            :lastRecord="lastRecord"
            :totRecords="totRecords"
            :isCurrentPageClass="isCurrentPageClass"
            :perpage="perpage"
            :getLastPageClass="getLastPageClass"
            :totPage="totPage"
            :getPages="getPages"
            @handlePerPage="handlePerPage"
            @paginate="paginate"
            @last="last"
            @getDataByPage="getDataByPage"
            :showPerPage="showPerPage"
             />
            </div>

            <div></div>
          </div>
      </v-col>
    </v-row>
    <v-row justify="center">
     <v-dialog
      v-model="toggleUpdateModal"
       persistent
      transition="dialog-bottom-transition"
      max-width="700"
    >
    <template>
        <v-card>
                    <v-card-title>
                      <span class="headline">Update Affinity</span>
                    </v-card-title>
                    <v-form
                      ref="form"
                      @submit="updateAffinityData"
                      method="put"
                    >
                      <v-container class="px-50" fluid>
                        <v-text-field
                          v-model="updateAffinity.affinityName"
                          label="Affinity Name"
                          required
                          :rules="nameRules"
                        ></v-text-field>
                        <div style="color: red">
                          {{ upmessage1 }}
                        </div>
                      </v-container>
                      <v-container class="px-50" fluid>
                        <v-select
                          :items="categories"
                          label="Select Category"
                          multiple
                          v-model="updateAffinity.category"
                          @change="findSubCateg(updateAffinity.category)"
                        ></v-select>
                        <v-select
                          :items="subcategories"
                          label="Sub-categories"
                          multiple
                          v-model="updateAffinity.subCategory"
                        ></v-select>
                      </v-container>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue darken-1"
                          @click="updateAffinityData"
                          type="submit"
                        >
                          Update
                        </v-btn>
                        <v-btn class="close_button mx-2" text @click="toggleUpdateModal = false">
                          Close
                        </v-btn>
                      </v-card-actions>
                    </v-form>
                  </v-card>
	  </template>
     </v-dialog>
</v-row>
    <!-- v-data-table-ends -->
  </div>
</template>

<script>
import axios from "axios";
import Index from "./Index.vue";
import Buttons from "./component/Buttons.vue";
import Item from "../../components/base/Item.vue";
import Pagination from "./component/Pagination.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  components: { Index, Buttons, Loading, Pagination },
  data() {
    return {
      toggleUpdateModal:false,
      //////table data starts here////
      selected: [],
      options: {},
      sort: "",
      affinity_data: [],
      show_zero_product_count:'no',
      totalAffinity: 0,
      editReportMode: false,
      fromDateMenu: false,
      fromDateVal: null,
      //  minDate: "2020-01-05",
      //  maxDate: "2019-08-30",
      headers: [
        {
          text: "",
          value: "selectCheckbox",
          width: "10%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Affinity",
          value: "affinity_name",
          width: "30%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Category",
          value: "affinity_categ",
          width: "25%",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Product Count",
          value: "product_count",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      ////// table data ends here
      checkbox: false,
      affinity: [],
      checkbox1: false,
      dialog: false,
      dialog1: false,
      dialog2: false,
      categories: [],
      searchCategories: [],
      query: "",
      categ: "",
      category: "",
      deleteItems: [],
      checked_affinityid: [],
      checkcedstr: "",
      categor_count: [],
      product_data: [],
      productCount: [],
      affinityIds: [],
      message1: "",
      message2: "",
      upmessage1: "",
      upmessage2: "",
      allSelected: false,
      affinityNames: [],
      addAffinity: {
        affinityName: null,
        category: [],
        subCategory: [],
      },
      updateAffinity: {
        affinityId: null,
        affinityName: null,
        category: [],
        subCategory: [],
      },
      subCategory: [],
      subcatName: [],
      subcategories: [],
      checked: false,
      nameRules: [(v) => !!v || "Name is required"],
      //pagination code
      perpage: 50,
      totRecords: null,
      page: 1,
      isLoading: true,
      fullPage: true,
      showPerPage: false,
      ///
    };
  },
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: function () {
      return this.page;
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totRecords) / parseInt(this.perpage));
    },
    ///////
  },
  methods: {
    //pagination methods
    checkIfOperationExistForModule(type){
        return this.$utils.checkIfOperationExistForModule(this, type)
    },
    getDataByPage(value) {
      console.log(value);
      this.page = value;
      this.getAffinity(this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.getAffinity(this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.getAffinity(this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.getAffinity(this.page);
    },
    /////
    resetSearch(){
      if(this.query != null || this.categ!= ""){
      this.page = 1,
      this.query = "",
      this.categ = ""
      this.show_zero_product_count = ''
      this.getAffinity(1);
      }
     },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.dialog = false;
      this.dialog1 = false;
      this.message1 = "";
      this.upmessage1 = "";
      //this.$refs.form.resetValidation();
      //this.$refs.form.reset();
    },
    getAffinity(page = "", query = "", categ = "") {
      let check = this;
      let path = check.$url("AFFINITY_SEARCH");
      //let path = 'http://0.0.0.0:5011/affinity-search'
      let page_no = page == '' ? this.page : page
      check.isLoading = true;
      let searchData ={
        "q": check.query.trim(),
        "categ": check.categ,
        "page": page_no,
        "perPage":this.perpage,
        "show_zero_product_count": this.show_zero_product_count
      }
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: check })
        .post(path,searchData)
        .then((res)=>{
          return res.data.payload
        }).then((res)=>{
          check.isLoading = false;
          this.affinity = res.data[0];
          let all_cat_names_arr = []
          if (res.data[1].length) {
            check.categories = []
            check.searchCategories = []
            check.searchCategories = [{ text: "All", value: "" }];
            res.data[1].forEach(function (item) {
              all_cat_names_arr.push(item[1]);
              let a = {
                text: item[1],
                value: item[0],
              };
              check.categories.push(a);
              check.searchCategories.push(a);
            });
          }
          //console.log(all_cat_names_arr);
          this.pageCount = res.data[2];
          if(this.totRecords != res.data[5]){
            this.page = 1
          }
          this.totalAffinity = res.data[5];
          this.totRecords = res.data[5];
          this.subCategory = res.data[3];
          let subcateg = this.subCategory;
          let affinity = this.affinity;
          if (Array.isArray(affinity) && affinity.length) {
            this.affinity_data = [];
            let i;
            for (i = 0; i < affinity.length; i++) {
              let cat_names =  []
              if(affinity[i][2] != '' && affinity[i][2] != null){
                let all_aff_cat_names_arr = affinity[i][2].split(',');
                if(all_aff_cat_names_arr.length){
                  all_aff_cat_names_arr.forEach((c)=>{
                    if(all_cat_names_arr.includes(c)){
                      cat_names.push(c)
                    }
                  })
                }

              }

              let tempobj = {
                affinity_id: affinity[i][0],
                affinity_name: affinity[i][1],
                affinity_categ: cat_names.length ? cat_names.join(", ") : '',
                product_count: affinity[i][4] == null ? 0 : affinity[i][4],
              };
              this.affinity_data.push(tempobj);
              this.affinityNames.push(affinity[i][1]);
            }
          }else{
            this.pageCount = res.data[2];
            this.totalAffinity = res.data[5];
            this.totRecords = res.data[5];
          }

          if (this.affinity == 0) {
            this.affinity_data = [];
            this.affinityIds = [];
            check.isLoading = false;
          } else {
            let temp = this;
            temp.affinityIds = [];
            temp.affinity.forEach(function (o, i) {
              temp.affinityIds.push(o[0]);
            });
            temp.subcatName = [];
            temp.subCategory.forEach(function (o, i) {
              temp.subcatName.push(o[1]);
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    addAffinityData(e) {
      e.preventDefault();
      let check = this;
      if (
        this.addAffinity.affinityName.trim() != "" &&
        this.addAffinity.affinityName != null
      ) {
        check.isLoading = true
        this.addAffinity.affinityName = this.addAffinity.affinityName.trim()
        this.$fetch({ requiresAuth: true, operation : 'add' , vueScope: check })
          .post(this.$url("AFFINITIES"), this.addAffinity)
          .then((result) => {
            check.isLoading = false
            console.log(result.data.payload[0]);
            this.message1 = result.data.payload[0].affinityID;
            this.message2 = result.data.payload[0].affinityName;
            console.log(result.data.payload[0].affinityID);
            if (this.message2 == null) {
              this.message1 = result.data.payload[0].affinityID;
              this.dialog = true;
            } else {
              this.message1 = null;
              this.dialog = false;
              if (result.data.statusCode == 200 && this.message1 == null) {
                check.$swal.fire({
                  icon: "success",
                  title: "Record(s) added successfully",
                  text: result.data.payload.message,
                });
                check.addAffinity = {
                                    affinityName: null,
                                    category: [],
                                    subCategory: [],
                                  }
              }
              check.getAffinity();
              check.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           check.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToUpdate(e, atchid) {
      let view = this;
      view.toggleUpdateModal = true
      view.affinity.forEach(function (o, i) {
        if (o[0] == atchid) {
          if (o[3] != null && o[3].length) {
            let parentCat = o[3].split(","); //parent category
            let subCat = view.subCategory; // all subcatego
            let cat_array = [];
            let subCa = [];
            parentCat.forEach(function (item, i) {
              view.categories.forEach((parent_cat) =>{
                if(parent_cat.value == parseInt(item)){
                  if (!cat_array.includes(parseInt(item))) {
                    cat_array.push(parseInt(item));
                  }
                }
              })
            });
            parentCat.forEach((prcat)=>{
              if(!cat_array.includes(parseInt(prcat))){
                if (!subCa.includes(parseInt(prcat))) {
                    subCa.push(parseInt(prcat));
                  }
              }
            })
            console.log(subCa);
            view.updateAffinity.category = cat_array;
            view.updateAffinity.subCategory = subCa;
          }
          view.updateAffinity.affinityName = o[1];
          view.updateAffinity.affinityId = o[0];
          console.log("add to update called")
        }
      });
      this.findSubCateg(this.updateAffinity.category);
    },
    updateAffinityData(e) {
      e.preventDefault();
      let data = this;
      console.log("update affinity called")
      if (
        data.updateAffinity.affinityName.trim() != "" &&
        data.updateAffinity.affinityName != null
      ) {
        data.isLoading = true
        data.updateAffinity.affinityName = data.updateAffinity.affinityName.trim()
        console.log(data.subcategories.value);
        data.subcategories.forEach(function (subcateg, i) {
          data.updateAffinity.subCategory.forEach(function (selcateg, i) {
            if (selcateg == subcateg) {
              data.updateAffinity.subCategory = [];
              data.updateAffinity.subCategory.push(selcateg);
            }
          });
        });
        console.log("put method called")
        //'http://0.0.0.0:5011/affinities'
        this.$fetch({ requiresAuth: true, operation: 'edit', vueScope: data })
          .put(data.$url("AFFINITIES"), data.updateAffinity)
          .then((result) => {
            data.isLoading = false
            console.log(result.data.payload.affinityID);
            this.upmessage1 = result.data.payload.affinityID;
            this.upmessage2 = result.data.payload.affinityName;
            console.log(result.data.payload.affinityID);
            if (this.upmessage2 == null) {
              this.upmessage1 = result.data.payload.affinityID;
            } else {
              this.upmessage1 = null;
              if (result.data.statusCode == 200) {
                this.$swal.fire({
                  icon: "success",
                  title: "Record(s) updated successfully.",
                  text: result.data.payload.message,
                });
                data.updateAffinity.category = [];
                data.updateAffinity.subCategory = [];
                this.toggleUpdateModal = false
              }
              this.getAffinity();
              this.reset();
            }
            console.log(result);
          }).catch((error) => {
           console.error(error);
           data.isLoading = false
           this.$swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Something went wrong!",
                });
        });
      }
    },
    addToDelete(e, atchid) {
      this.checkedthis = false;
      //e.target.checked
      if (e.target.checked) {
        if (this.checked_affinityid.indexOf(e.target.value) == -1) {
          this.checked_affinityid.push(e.target.value);
        }
      } else {
        this.checked_affinityid.splice(
          this.checked_affinityid.indexOf(e.target.value),
          1
        );
      }
      let checkcedstr = this.checked_affinityid.join(",");
      this.checkcedstr = checkcedstr;
      console.log(this.checkcedstr);
    },
    deleteAffinity(e) {
      e.preventDefault();
      let check = this;
      console.log(this.deleteItems);
      check.isLoading = true
      this.$fetch({ requiresAuth: true, operation : 'delete', vueScope: check})
        .delete(this.$url("AFFINITIES"), {
          params: { id: this.deleteItems },
        })
        .then((response) => {
          if (response.data.statusCode == 200) {
            check.isLoading = false
            this.$swal.fire({
              icon: "success",
              title: "Selected record(s) has been deleted.",
              text: response.data.payload.message,
            });
          }
          this.getAffinity();
          this.deleteItems = [];
        });
    },
    selectAll: function () {
      let delaffinity = this.affinity;
      if (this.allSelected == true) {
        if (Array.isArray(delaffinity) && delaffinity.length) {
          this.deleteItems = [];
          let i;
          for (i = 0; i < delaffinity.length; i++) {
            if (this.affinity_data[i].product_count == 0) {
              this.deleteItems.push(delaffinity[i][0]);
              console.log("gsdhghshsjss");
            }
          }
        }
      } else if (this.allSelected == false) {
        this.deleteItems = [];
      }
    },
    select: function () {
      this.allSelected = false;
    },
    findSubCateg(atchid) {
      let view = this;
      view.subcategories = [];
      let sub_cat_ids = []
      view.isLoading = true
      //'http://0.0.0.0:5011/affinity-search'
      this.$fetch({ requiresAuth: true, operation : 'view', vueScope: view })
        .post(view.$url("AFFINITY_SEARCH"), { parentId: atchid })
        .then((res) =>{
          return res.data.payload
        }).then((res)=>{
          view.isLoading = false
          if (res.data[4].length) {
            res.data[4].forEach(function (item) {
              item.forEach(function (o) {
                let a = {
                  text: o[1],
                  value: o[0],
                };
                view.subcategories.push(a);
                sub_cat_ids.push(o[0])
                // view.updateAffinity.subCategory.push(a);
              });
            });
            if(view.addAffinity.subCategory.length){
              view.addAffinity.subCategory.forEach((c)=>{
                if(sub_cat_ids.length){
                  if(!sub_cat_ids.includes(c)){
                  let index = view.addAffinity.subCategory.indexOf(c);
                    if (index > -1) {
                      view.addAffinity.subCategory.splice(index, 1);
                    }
                  }
                }
              })
            }
            if(view.updateAffinity.subCategory.length){
              view.updateAffinity.subCategory.forEach((c)=>{
                if(sub_cat_ids.length){
                  if(!sub_cat_ids.includes(c)){
                  let index = view.updateAffinity.subCategory.indexOf(c);
                    if (index > -1) {
                      view.updateAffinity.subCategory.splice(index, 1);
                    }
                  }
                }
              })
            }
          }
        });
    },
    submit() {
      this.$v.$touch();
    },
    searchAffinity: function () {
      this.getAffinity(1, this.query);
      console.log(this.query);
    },
  },
  watch: {
 '$store.state.Rawdata.current_active_module': function(o) {
 if(o){
 this.getAffinity(1);
 }
 },
 page: function (ob) {
 this.allSelected = false;
 this.deleteItems = [];
 //this.getAffinity(ob, this.query);
 },
 },
};
</script>
